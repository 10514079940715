<template>
  <div>
    <v-card outlined>
      <v-card-title>
        <app-title>Financeiro</app-title>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="d-flex justify-start">
            <app-month-select @input="handleChangeMonth($event)" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-1" cols="12" md="4">
            <app-text-search-field @input="handleSearchInput($event)" :isLoading="loading"
              @filter="showFilter = !showFilter" />
          </v-col>
          <v-col md="8" class="d-md-flex d-none justify-end">
            <TransactionsExportOptions rounded class="mr-4" outlined v-if="$acl.canViewTransactions()"
              @export="handleTransactionsExport()" @sessionsExport="handleSessionsExport()" />
            <AddTransactionBtn rounded color="primary" v-if="$acl.canCreateTransactions()"
              @click="openNewTransactionForm($event)" />
          </v-col>
        </v-row>
        <template v-if="showFilter">
          <v-row class="d-flex align-center">
            <v-col cols="12" md="4">
              <CustomerSearch v-model="searchParams.customer" clearable @input="setCustomer($event)" />
            </v-col>
            <v-col v-if="this.$acl.isClinic()" cols="12" md="4">
              <select-professional v-model="searchParams.professional" clearable
                :disabled="this.$acl.forceFilterOwnTransactions()" @input="setProfessional($event), select()" />
            </v-col>
            <v-checkbox label="Apenas com recibo" v-model="searchParams.has_receipt" :checked-value="true"
              :unchecked-value="false" @change="select()" />
          </v-row>
          <v-row class="mb-2">
            <v-col cols="6" md="2">
              <app-date-picker @input="select()" v-model="searchParams.date_start" label="Data Inicial" />
            </v-col>
            <v-col cols="6" md="2">
              <app-date-picker @input="select()" v-model="searchParams.date_end" label="Data Final" />
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-center">
              <v-chip-group @change="select()" v-model="searchParams.status" multiple>
                <v-chip color="success" small value="paid" filter>
                  Pago
                </v-chip>
                <v-chip color="primary" small value="pending" filter>
                  A Receber
                </v-chip>
                <v-chip color="error" small value="canceled" filter>
                  Cancelado
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col cols="8">
            <v-card outlined style="border-radius: 16px">
              <v-card-text class="pa-0">
                <TransactionsList :transactions="transactions" @click="openTransactionForm($event)" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card outlined>
              <v-card-text class="pa-6">
                <div class="d-flex justify-space-between" style="width: 100%">
                  <div>
                    <div class="text-center">
                      <v-avatar size="40px" color="#EDEAFC">
                        <app-icon color="primary">event</app-icon>
                      </v-avatar>
                    </div>
                    <div class="text-center mt-2">
                      <span class="secondary--text">Sessões</span>
                    </div>
                    <div class="text-center">
                      <h3 class="secondary--text">{{ stats.sessions }}</h3>
                    </div>
                  </div>
                  <div>
                    <div class="text-center">
                      <v-avatar size="40px" color="#E6F2E6">
                        <app-icon color="success">done</app-icon>
                      </v-avatar>
                    </div>
                    <div class="text-center mt-2">
                      <span class="secondary--text">Atendidas</span>
                    </div>
                    <div class="text-center">
                      <h3 class="secondary--text">{{ stats.shown }}</h3>
                    </div>
                  </div>
                  <div>
                    <div class="text-center">
                      <v-avatar size="40px" color="#FBE7E7">
                        <app-icon color="error">error</app-icon>
                      </v-avatar>
                    </div>
                    <div class="text-center mt-2">
                      <span class="secondary--text">Faltas</span>
                    </div>
                    <div class="text-center">
                      <h3 class="secondary--text">{{ stats.missed }}</h3>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <br />
            <TransactionStats :transactionsSum="transactionsSum" :balance="balance" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <TransactionForm @store="select()" ref="TransactionForm" />
  </div>
</template>

<script>
import TransactionsMixin from "@/mixins/Transactions/TransactionsMixin.js";
import TransactionForm from "@/components/transactions/form/TransactionForm.vue";
import TransactionStats from "@/components/transactions/sections/new/TransactionStats.vue";
import TransactionsList from "@/components/transactions/lists/TransactionsList.vue";

export default {
  mixins: [TransactionsMixin],
  components: {
    TransactionForm,
    TransactionStats,
    TransactionsList,
  },
  data: () => ({}),
};
</script>

<style lang="sass" scoped>
h3
  color: #33303E
  font-size: 18px
  font-family: Poppins
  font-style: normal
  font-weight: 700
  line-height: 27px
</style>