<template>
  <div>
    <template v-if="!$vuetify.breakpoint.mobile">
      <Desktop />
    </template>
    <template v-if="$vuetify.breakpoint.mobile">
      <Mobile />
    </template>
  </div>
</template>

<script>
import Mobile from "@/components/transactions/views/Mobile.vue";
import Desktop from "@/components/transactions/views/Desktop.vue";

export default {
  components: {
    Mobile,
    Desktop,
  },
};
</script>

<style></style>
