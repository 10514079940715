<template>
  <div>
    <div class="d-flex justify-space-between">
      <div class="d-flex align-center">
        <div class="pr-3">
          <v-avatar size="40px" color="#EDEAFC">
            <app-icon color="primary">account_balance_wallet</app-icon>
          </v-avatar>
        </div>
        <div>
          <small class="text--secondary">Saldo</small>
          <h3>{{ $format.decimal(balance.value_sum) }}</h3>
        </div>
      </div>
      <div>
        <div class="d-flex align-center">
          <div class="pr-3">
            <v-avatar size="40px" color="#E6F2E6">
              <app-icon color="success">attach_money</app-icon>
            </v-avatar>
          </div>
          <div>
            <small class="text--secondary">Balanço</small>
            <h3>{{ $format.decimal(sum(monthBalance)) }}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <template v-if="showMore">
        <v-card elevation="0" color="grey lighten-4">
          <v-card-text class="pa-2">
            <div class="mt-4">
              <div class="d-flex justify-space-between">
                <div>
                  <app-icon class="mr-1" color="success">add</app-icon>
                  <span class="font-weight-bold secondary--text">Entradas</span>
                </div>
                <div class="text-right">
                  <span class="success--text">{{
                    $format.decimal(sum(incomePaid))
                  }}</span>
                  <br />
                  <small
                    >A Receber: {{ $format.decimal(sum(incomeUnpaid)) }}</small
                  >
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div class="d-flex justify-space-between">
                <div>
                  <app-icon class="mr-1" color="error">remove</app-icon>
                  <span class="font-weight-bold secondary--text">Saídas</span>
                </div>
                <div class="text-right">
                  <span class="error--text">{{
                    $format.decimal(sum(outcomePaid))
                  }}</span>
                  <br />
                  <small
                    >A pagar: {{ $format.decimal(sum(outcomeUnpaid)) }}
                  </small>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </template>
      <div class="text-right">
        <v-btn
          class="px-0 my-2"
          small
          color="secondary"
          text
          @click="showMore = !showMore"
        >
          <template v-if="!showMore">
            <app-icon class="text--secondary">expand_more</app-icon>
            <span class="text--secondary">Mostrar mais</span>
          </template>
          <template v-if="showMore">
            <app-icon class="text--secondary">expand_less</app-icon>
            <span class="text--secondary">Mostrar menos</span>
          </template>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    transactionsSum: {},
    balance: {
      default: {},
    },
  },
  // todo warning duplicated code
  data: () => ({
    showMore: false,

    incomePaid: (row) => row.type == "in" && row.status == "paid",
    incomeUnpaid: (row) => row.type == "in" && row.status == "pending",
    totalIncome: (row) => row.type == "in" && row.status !== "canceled",
    outcomePaid: (row) => row.type == "out" && row.status == "paid",
    outcomeUnpaid: (row) => row.type == "out" && row.status == "pending",
    totalOutcome: (row) => row.type == "out" && row.status !== "canceled",
    monthBalance: (row) => row.status == "paid",

    received: (row) => row.type == "in",
  }),

  computed: {},

  methods: {
    sum(func) {
      return this.transactionsSum.reduce((sum, row) => {
        if (func(row)) {
          return sum + parseFloat(row.value_sum);
        }

        return sum;
      }, 0);
    },

    percent(total, part) {
      let sumTotal = Math.abs(this.sum(total));
      let sumPart = Math.abs(this.sum(part));

      return sumTotal > 0 ? (sumPart * 100) / sumTotal : 0;
    },
  },
};
</script>

<style></style>
