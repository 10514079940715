<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="600px"
  >
    <v-card>
      <v-card-title class="headline">
        <span>Financeiro X Sessões</span>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-row v-if="$acl.isClinic()">
          <v-col>
            <select-professional
              :disabled="this.$acl.forceFilterOwnTransactions()"
              @input="setProfessional($event)"
              v-model="searchParams.professional"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <app-select
              label="Ano"
              :items="yearOptions"
              v-model="searchParams.year"
            />
          </v-col>
          <v-col>
            <GroupSelect
              v-model="searchParams.group"
              @input="setGroup($event)"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <app-select
              v-model="searchParams.session_status"
              :items="sessionStatusOptions"
              label="Status da Sessão"
            />
          </v-col>
          <v-col>
            <app-select
              v-model="searchParams.transaction_status"
              :items="transactionStatusOptions"
              label="Status da Financeiro"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="handleExport()">Exportar</app-confirm-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectProfessional from "@/components/app/forms/SelectProfessional.vue";
import GroupSelect from "@/components/group/ui/GroupSelect.vue";

export default {
  components: {
    SelectProfessional,
    GroupSelect,
  },

  data() {
    return {
      dialog: false,
      searchParams: {
        year: null,
        professional_id: null,
        group_id: null,
        session_status: null,
        transaction_status: null,
      },
      sessionStatusOptions: [
        { value: "pending", text: "Pendente" },
        { value: "canceled", text: "Cancelado" },
        { value: "missed", text: "Faltou" },
        { value: "shown", text: "Compareceu" },
      ],

      transactionStatusOptions: [
        { value: "pending", text: "Pendente" },
        { value: "canceled", text: "Cancelado" },
        { value: "paid", text: "Pago" },
      ],
    };
  },

  computed: {
    yearOptions() {
      return Array.from(
        new Array(new Date().getFullYear() - 2019 + 1),
        (val, index) => 2019 + index
      );
    },
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    open() {
      this.reset();

      this.processePermission();

      this.dialog = true;
    },

    processePermission() {
      if (this.$acl.forceFilterOwnTransactions()) {
        this.setProfessional(this.user);
      }
    },

    setProfessional(professional) {
      if (professional) {
        this.searchParams.professional = {
          id: professional.id,
          name: professional.name,
        };
        this.searchParams.professional_id = professional.id;
      }

      if (!professional) {
        this.searchParams.professional = null;
        this.searchParams.professional_id = null;
      }
    },

    setGroup(group) {
      if (group) {
        this.searchParams.group = {
          id: group.id,
          name: group.name,
        };
        this.searchParams.group_id = group.id;
      }

      if (!group) {
        this.searchParams.group = null;
        this.searchParams.group_id = null;
      }
    },

    handleExport() {
      this.$loading.start();
      this.$http
        .download("transactions/export/session-transaction", this.searchParams)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Relação Financeiro X Sessões ${this.searchParams.year} .xlsx`
          );
          document.body.appendChild(link);
          link.click();
          this.$loading.finish();
          this.dialog = false;
        })
        .catch((error) => {
          this.$loading.finish();
          this.dialog = false;
        });
    },

    reset() {
      this.searchParams = {
        year: new Date().getFullYear(),
        professional_id: null,
        group_id: null,
        transaction_status: null,
        session_status: null,
      };
    },
  },
};
</script>

<style>
</style>