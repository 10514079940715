<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="500px"
  >
    <v-card>
      <v-card-title class="headline">
        <span> Relatório para fins de IR </span>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-row v-if="$acl.isClinic()">
          <v-col>
            <SelectProfessional
              :disabled="this.$acl.forceFilterOwnTransactions()"
              @input="setProfessional($event)"
              v-model="searchParams.professional"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex flex-column">
            <app-select
              label="Ano"
              :items="yearOptions"
              v-model="searchParams.year"
            />

            <!-- <v-col class="d-flex align-start"> -->
            <v-checkbox
              label="Apenas com recibo"
              v-model="searchParams.has_receipt"
              :checked-value="true"
              :unchecked-value="false"
              hide-details
            />
          </v-col>
          <!-- </v-col> -->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="handleExport()">Confirmar</app-confirm-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectProfessional from "@/components/app/forms/SelectProfessional.vue";

export default {
  components: {
    SelectProfessional,
  },

  data() {
    return {
      dialog: false,
      searchParams: {
        year: null,
        professional_id: null,
        has_receipt: true,
      },
    };
  },

  computed: {
    yearOptions() {
      return Array.from(
        new Array(new Date().getFullYear() - 2020 + 1),
        (val, index) => 2020 + index
      );
    },
    user() {
      return this.$store.state.auth.user;
    },
  },

  created() {
    this.reset();
  },

  methods: {
    open() {
      this.reset();

      this.processePermission();

      this.dialog = true;
    },

    processePermission() {
      if (this.$acl.forceFilterOwnTransactions()) {
        this.setProfessional(this.user);
      }
    },

    setProfessional(professional) {
      if (professional) {
        this.searchParams.professional = {
          id: professional.id,
          name: professional.name,
        };
        this.searchParams.professional_id = professional.id;
        this.searchParams.professional_name = professional.name;
      }

      if (!professional) {
        this.searchParams.professional = null;
        this.searchParams.professional_id = null;
        this.searchParams.professional_name = null;
      }
    },

    setGroup(group) {
      if (group) {
        this.searchParams.group = {
          id: group.id,
          name: group.name,
        };
        this.searchParams.group_id = group.id;
      }

      if (!group) {
        this.searchParams.group = null;
        this.searchParams.group_id = null;
      }
    },

    handleExport() {
      const link = this.$router.resolve({
        name: "BillingExport",
        query: this.searchParams,
      });
      window.open(link.href, "_blank");
    },

    reset() {
      this.searchParams = {
        year: new Date().getFullYear(),
        professional_id: null,
        has_receipt: true,
      };
    },
  },
};
</script>

<style>
</style>