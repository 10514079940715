<template>
  <app-pagination :data="transactions">
    <v-list dense flat>
      <template v-for="(date, dateIndex) of dates">
        <div class=" mt-2" :key="dateIndex">
          <small class=" mt-4 text--secondary">
            {{ $format.dateBr(date) }}
          </small>
        </div>
        <v-divider :key="`divider${dateIndex}`" />

        <v-list-item-group :key="`lsitGroup${dateIndex}`">
          <template v-for="(item, index) in getTransactionsByDate(date)">
            <v-list-item
              class="px-0 my-0 align-center"
              :key="index"
              @click="$emit('show', item)"
            >
              <!-- <v-list-item-icon>
                <v-icon
                  v-text="icons[item.origin][item.type]"
                  :color="typeColor[item.type]"
                />
              </v-list-item-icon> -->

              <v-list-item-content>
                <div class="d-flex align-center">
                <div>
                  <v-icon
                    left
                    v-text="icons[item.origin][item.type]"
                    :color="typeColor[item.type]"
                  />
                </div>
                <div>
                  {{ getDescription(item) }}
                  <br>
                  <small class="text--secondary">
                    {{ getSubDescription(item) }} 
                  </small>
                </div>
                </div>
              </v-list-item-content>

              <v-list-item-action class="mr-3" @click.stop>
                <span :class="['d-inline', getValueClass(item)]">
                  {{ $format.decimal(item.value) }}
                </span>
                <app-transaction-status-chip x-small :transaction="item" />
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list-item-group>
      </template>
    </v-list>
  </app-pagination>
</template>

<script>
export default {
  props: {
    transactions: {
      default() {
        return {
          data: [],
        };
      },
    },
  },

  data: () => ({
    icons: {
      custom: {
        in: "mdi-plus",
        out: "mdi-minus",
      },
      session: {
        in: "mdi-plus",
      },
      sessionGroup: {
        in: "mdi-plus",
      },
    },

    typeColor: {
      in: "success",
      out: "error",
    },
  }),

  computed: {
    dates() {
      return [...new Set(this.transactions.data.map((row) => row.date))];
    },
  },

  methods: {
    getTransactionsByDate(date) {
      return this.transactions.data.filter((row) => row.date == date);
    },

    getDescription(item) {
      if (item.customer_id == null) {
        return item.description;
      }

      return item.customer.name;
    },

    getSubDescription(item) {
    
      return item.customer_id != null ? item.description : "";
    },

    getValueClass(item) {
      if (item.status == "paid") {
        return item.value > 0 ? "success--text" : "error--text";
      }

      return "text--disabled";
    },
  },
};
</script>

<style></style>
