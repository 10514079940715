<template>
  <div>
    <portal v-if="$acl.canCreateTransactions()" to="mobileLeftAppBar">
      <div class="d-flex justify-end">
        <TransactionsExportOptions
          rounded
          class="mr-4"
          v-if="$acl.canViewTransactions()"
          @export="handleTransactionsExport()"
        />
        <AddTransactionBtn
          rounded
          v-if="$acl.canCreateTransactions()"
          @click="openNewTransactionForm($event)"
        />
      </div>
    </portal>

    <v-row>
      <v-col>
        <app-text-search-field
          @input="handleSearchInput($event)"
          :isLoading="loading"
          @filter="showFilter = !showFilter"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <app-month-select @input="handleChangeMonth($event)" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-chip-group v-model="viewMode" mandatory>
          <v-chip color="primary" filter :value="'transactions'">
            Registros
          </v-chip>
          <v-chip outlined filter :value="'balance'">Balanço </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <template v-if="showFilter">
      <v-row>
        <v-col cols="12" md="4">
          <CustomerSearch
            v-model="searchParams.customer"
            clearable
            @input="setCustomer($event)"
          />
        </v-col>
        <v-col v-if="this.$acl.isClinic()" cols="12" md="4">
          <select-professional
            v-model="searchParams.professional"
            clearable
            :disabled="this.$acl.forceFilterOwnTransactions()"
            @input="setProfessional($event), select()"
          />
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="6" md="2">
          <app-date-picker
            @input="select()"
            v-model="searchParams.date_start"
            label="Data Inicial"
          />
        </v-col>
        <v-col cols="6" md="2">
          <app-date-picker
            @input="select()"
            v-model="searchParams.date_end"
            label="Data Final"
          />
        </v-col>
        <div class="ml-2">
          <v-checkbox
            label="Apenas com recibo"
            v-model="searchParams.has_receipt"
            :checked-value="true"
            :unchecked-value="false"
            @change="select()"
          />
        </div>
        <v-col cols="12" md="4" class="d-flex justify-center">
          <v-chip-group
            @change="select()"
            v-model="searchParams.status"
            multiple
          >
            <v-chip color="success" small value="paid" filter> Pago </v-chip>
            <v-chip color="primary" small value="pending" filter>
              A Receber
            </v-chip>
            <v-chip color="error" small value="canceled" filter>
              Cancelado
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
    </template>

    <template v-if="viewMode === 'transactions'">
      <TransactionsList
        :transactions="transactions"
        @click="openTransactionForm($event)"
      />
    </template>

    <template v-if="viewMode === 'balance'">
      <v-card flat>
        <v-card-text class="pa-6">
          <div class="d-flex justify-space-between" style="width: 100%">
            <div>
              <div class="text-center">
                <v-avatar size="40px" color="#EDEAFC">
                  <app-icon color="primary">event</app-icon>
                </v-avatar>
              </div>
              <div class="text-center mt-2">
                <span class="secondary--text">Sessões</span>
              </div>
              <div class="text-center">
                <h3 class="secondary--text">{{ stats.sessions }}</h3>
              </div>
            </div>
            <div>
              <div class="text-center">
                <v-avatar size="40px" color="#E6F2E6">
                  <app-icon color="success">done</app-icon>
                </v-avatar>
              </div>
              <div class="text-center mt-2">
                <span class="secondary--text">Atendidas</span>
              </div>
              <div class="text-center">
                <h3 class="secondary--text">{{ stats.shown }}</h3>
              </div>
            </div>
            <div>
              <div class="text-center">
                <v-avatar size="40px" color="#FBE7E7">
                  <app-icon color="error">error</app-icon>
                </v-avatar>
              </div>
              <div class="text-center mt-2">
                <span class="secondary--text">Faltas</span>
              </div>
              <div class="text-center">
                <h3 class="secondary--text">{{ stats.missed }}</h3>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <TransactionStats :transactionsSum="transactionsSum" :balance="balance" />
    </template>
    <TransactionForm @store="select()" ref="TransactionForm" />
  </div>
</template>
<script>
import TransactionsMixin from "@/mixins/Transactions/TransactionsMixin.js";
import TransactionForm from "@/components/transactions/form/TransactionForm.vue";
import TransactionStats from "@/components/transactions/sections/new/TransactionStats.vue";
import TransactionsList from "@/components/transactions/lists/TransactionsList.vue";

export default {
  mixins: [TransactionsMixin],
  components: {
    TransactionForm,
    TransactionStats,
    TransactionsList,
  },
  data: () => ({
    viewMode: "transactions",
  }),
};
</script>

<style lang="sass" scoped>
h3
  color: #33303E
  font-size: 18px
  font-family: Poppins
  font-style: normal
  font-weight: 700
  line-height: 27px
</style>