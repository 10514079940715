<template>
  <div>
    <v-dialog
      persistent
      v-model="dialog"
      max-width="500px"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title primary-title> {{ title }} </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="mt-5">
              <app-text-field v-model="form.description" label="Descrição" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea v-model="form.details" label="Detalhes" rows="1" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <app-time-picker v-model="form.date" label="Data" />
            </v-col>
            <v-col>
              <app-number-field v-model="form.value" label="Valor" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false" text>Cancelar</v-btn>
          <v-btn @click="handleSave()" class="mr-2" color="primary"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import format from "date-fns/format";
export default {
  data() {
    return {
      dialog: false,
      form: null,
      baseForm: {
        type: "",
        value: null,
        description: null,
        details: null,
        date: format(new Date(), "yyyy-MM-dd"),
        origin: "custom",
        status: "pending",
      },
    };
  },
  created() {
    this.reset();
  },

  computed: {
    title() {
      if (this.form.type == "in") {
        return "Nova Entrada";
      }
      if (this.form.type == "out") {
        return "Nova Saida";
      }
      // return this.data
    },
  },

  methods: {
    async open(type = null) {
      await this.reset();

      this.form.type = type;
      this.dialog = true;
    },
    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    handleSave() {
      this.$http
        .store("transactions/transactions", this.form)
        .then((response) => {
          this.dialog = false;
          this.$emit("store");
        });
    },
  },
};
</script>

<style>
</style>