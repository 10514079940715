<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <template v-if="!$vuetify.breakpoint.mobile">
          <v-btn outlined v-bind="{ ...attrs, ...$attrs }" v-on="{ ...on, ...$listeners }">
            <span>Exportar</span>
          </v-btn>
        </template>
        <template v-else>
          <v-btn v-bind="attrs" v-on="on" fab small text elevation="0" plain>
            <app-icon>download</app-icon>
          </v-btn>
        </template>
      </template>
      <v-list dense>
        <v-list-item @click="$emit('export')">
          <v-list-item-content>
            <v-list-item-title>
              Exportar resultados da pesquisa
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="handleTransactionsAndSessionExport()">
          <v-list-item-content>
            <v-list-item-title>
              Relação Financeiro X Sessões
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="handleBillingExport()">
          <v-list-item-content>
            <v-list-item-title>Relatório para fins de IR </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$emit('sessionsExport')">
          <v-list-item-content>
            <v-list-item-title>Relatório de sessões</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <BillingExportDialog ref="BillingExportDialog" />
    <SessionTransactionExportDialog ref="SessionTransactionExportDialog" />
  </div>
</template>

<script>
import BillingExportDialog from "@/components/transactions/sections/BillingExportDialog.vue";
import SessionTransactionExportDialog from "@/components/transactions/sections/SessionTransactionExportDialog.vue";

export default {
  components: {
    BillingExportDialog,
    SessionTransactionExportDialog,
  },

  methods: {
    handleBillingExport() {
      this.$refs.BillingExportDialog.open();
    },
    handleTransactionsAndSessionExport() {
      this.$refs.SessionTransactionExportDialog.open();
    },

  },
};
</script>

<style></style>
