<template>
  <div>
    <div :id="transactionsListStyle">
      <template v-for="(item, index) in transactions.data">
        <div
          @click="$emit('click', item)"
          :class="getHoverClass(index)"
          :key="index"
        >
          <div class="item">
            <div class="pr-4">
              <v-icon
                v-text="icons[item.origin][item.type]"
                :color="typeColor[item.type]"
              />
            </div>
            <div class="descriptions">
              <div class="body-04">
                {{ $format.dateBr(item.date) }}
              </div>
              <div class="body-02 my-2">
                {{ getDescription(item) }}
              </div>
              <div class="body-03">
                {{ getSubDescription(item) }}
              </div>
            </div>
            <div class="ml-auto totals">
              <span
                :class="getValueClass(item)"
                v-text="$format.moneyBr(Math.abs(item.value))"
              />
              <app-transaction-status-chip hide-icon :transaction="item" />
            </div>
          </div>
        </div>
        <div class="mx-6">
          <v-divider
            :key="`divider${index}`"
            v-if="index < transactions.data.length - 1"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    transactions: {},
  },

  data: () => ({
    icons: {
      custom: {
        in: "mdi-plus",
        out: "mdi-minus",
      },
      session: {
        in: "mdi-plus",
      },
      sessionGroup: {
        in: "mdi-plus",
      },
    },

    typeColor: {
      in: "success",
      out: "error",
    },
  }),

  computed: {
    transactionsListStyle() {
      return this.$vuetify.breakpoint.mobile
        ? "transactions-list-mobile"
        : "transactions-list-desktop";
    },
  },

  methods: {
    getDescription(item) {
      if (item.customer_id == null) {
        return item.description;
      }

      return item.customer.name;
    },

    getSubDescription(item) {
      return item.customer_id != null ? item.description : "";
    },

    getValueClass(item) {
      if (item.status == "paid") {
        return item.value > 0 ? "success--text" : "error--text";
      }
      return "text--disabled";
    },

    getHoverClass(index) {
      if (index == 0) {
        return "first-hover";
      }
      if (index == this.transactions.data.length - 1) {
        return "end-hover";
      }
      return "item-hover";
    },
  },
};
</script>


<style lang="sass" scoped>
#transactions-list-desktop
  h3
    color: #33303E
    font-size: 18px
    font-family: Poppins
    font-style: normal
    font-weight: 700
    line-height: 27px

  div.first-hover:hover
    background: rgba(0, 0, 0, 0.2)
    border-top-left-radius: 16px
    border-top-right-radius: 16px

  div.item-hover:hover
    background: rgba(0, 0, 0, 0.2)

  div.end-hover:hover
    background: rgba(0, 0, 0, 0.2)
    border-bottom-left-radius: 16px
    border-bottom-right-radius: 16px

  .body-02
    color: #624DE3
    font-size: 16px
    font-family: Poppins
    font-style: normal
    font-weight: 500
    line-height: 24px

  .body-03
    color: #8C8A97
    font-size: 14px
    font-family: Poppins
    font-style: normal
    font-weight: 500
    line-height: 21px

  .body-04
    color: #AAA8B8
    font-size: 12px
    font-family: Poppins
    font-style: normal
    font-weight: 500
    line-height: 18px
    letter-spacing: 1px

  .item
    display: flex
    flex-grow: 0.6
    align-items: center
    padding: 16px
    cursor: pointer

// mobile styles
#transactions-list-mobile
  .descriptions
    text-align: left
    width: 600px
    text-justify: justify-all
  .totals
    text-align: center
    width: 100%
  h3
    color: #33303E
    font-size: 18px
    font-family: Poppins
    font-style: normal
    font-weight: 700
    line-height: 27px

  // div.first-hover:hover
  //   background: rgba(0, 0, 0, 0.2)

  // div.item-hover:hover
  //   background: rgba(0, 0, 0, 0.2)

  // div.end-hover:hover
  //   background: rgba(0, 0, 0, 0.2)

  .body-02
    color: #907FF5
    font-family: Poppins
    font-size: 14px
    font-style: normal
    font-weight: 500
    line-height: 21px

  .body-03
    color: #8C8A97
    font-family: Poppins
    font-size: 12px
    font-style: normal
    font-weight: 500
    line-height: 18px
    letter-spacing: 1px
    // color: #8C8A97
    // font-size: 14px
    // font-family: Poppins
    // font-style: normal
    // font-weight: 500
    // line-height: 21px

  .body-04
    color: #8C8A97
    font-family: Poppins
    font-size: 12px
    font-style: normal
    font-weight: 500
    line-height: 18px
    letter-spacing: 1px

  .item
    display: flex
    flex-grow: 1
    align-items: center
    padding-top: 10px
    padding-bottom: 10px
    padding-left: 0px
    padding-right: 0px
</style>