<template>
  <v-card color="grey lighten-3 " elevation="0">
    <v-card-text>
      
      <div class="d-flex align-end justify-space-between mt-2">
        <h2>Saldo Atual</h2>
        <h1 class="mt-3">{{ $format.decimal(balance.value_sum) }}</h1>
      </div>
      <v-divider class="mt-6 mb-8" />

      <div class="mt-2">
        <div class="d-flex">
          <h2>Balanço</h2>
        </div>
        <div class="d-flex justify-space-between mt-2">
          <div>
            <span>(+) Entradas</span>
          </div>
          <div class="text-right">
            <h3>{{ $format.decimal(sum(incomePaid)) }}</h3>
          </div>
        </div>
        <div class="d-flex justify-space-between mt-2">
          <div>
            <span>(-) Saidas</span>
          </div>
          <div class="text-right">
            <h3>{{ $format.decimal(sum(outcomePaid)) }}</h3>
          </div>
        </div>
        <v-divider class="mt-1 mb-1" />
        <div class="d-flex justify-space-between mt-2">
          <div>
            <span>(=) Balanço do Mês</span>
          </div>
          <div class="text-right">
            <h3>{{ $format.decimal(sum(monthBalance)) }}</h3>
          </div>
        </div>
      </div>

      <v-divider class="mt-6 mb-8" />
      <div class="mt-4">
        <div class="d-flex">
          <h2>Entradas</h2>
          <small class="ml-2">(Este Mês)</small>
        </div>
        <v-progress-linear :value="percent(totalIncome, incomePaid)" color="success"></v-progress-linear>
        <div class="d-flex justify-space-between mt-2">
          <div>
            <h3>{{ $format.decimal(sum(incomePaid)) }}</h3>
            <span class="text--secondary">Recebidas</span>
          </div>
          <div class="text-right">
            <h3>{{ $format.decimal(sum(incomeUnpaid)) }}</h3>
            <span class="text--secondary">A Receber</span>
          </div>
        </div>
      </div>

      <div class="mt-8">
        <div class="d-flex">
          <h2>Saídas</h2>
          <small class="ml-2">(Este Mês)</small>
        </div>
        <v-progress-linear :value="percent(totalOutcome, outcomePaid)"  color="error"></v-progress-linear>
        <div class="d-flex justify-space-between mt-2">
          <div>
            <h3>{{ $format.decimal(sum(outcomePaid)) }}</h3>
            <span class="text--secondary">Pago</span>
          </div>
          <div class="text-right">
            <h3>{{ $format.decimal(sum(outcomeUnpaid)) }}</h3>
            <span class="text--secondary">A Pagar</span>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    transactionsSum: {
      default : []
    },
    balance : {
      default : {}
    }
  },

  data : () => ({
    
    incomePaid : (row) => row.type == 'in' && row.status == 'paid',
    incomeUnpaid : (row) => row.type == 'in' && row.status == 'pending',
    totalIncome : (row) => row.type == 'in' && row.status !== 'canceled',
    outcomePaid : (row) => row.type == 'out' && row.status == 'paid',
    outcomeUnpaid : (row) => row.type == 'out' && row.status == 'pending',
    totalOutcome : (row) => row.type == 'out' && row.status !== 'canceled',
    monthBalance : (row) => row.status == 'paid',

    received : (row) => row.type == 'in'
  }),

  computed : {
    
  },

  methods : {
    sum(func) {
      return this.transactionsSum.reduce((sum, row) => {
        if (func(row)) {

          return sum + parseFloat(row.value_sum)
        }

        return sum
      }, 0);
    },

    percent(total, part) {
      
      let sumTotal = Math.abs(this.sum(total))
      let sumPart = Math.abs(this.sum(part))

      return (sumTotal > 0) ? sumPart * 100 / sumTotal : 0
    }
    
  }
};
</script>

<style>
</style>