<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <template v-if="!$vuetify.breakpoint.mobile">
        <app-btn color="primary" v-bind="{ ...attrs, ...$attrs }" v-on="on">
          <app-icon left>add</app-icon>
          <span>Novo</span>
        </app-btn>
      </template>
      <template v-else>
        <app-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          fab
          small
          elevation="0"
        >
          <app-icon>add</app-icon>
        </app-btn>
      </template>
    </template>
    <v-list>
      <v-list-item @click="$emit('click', 'in')">
        <v-list-item-action>
          <v-icon color="success">mdi-plus</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Entrada</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$emit('click', 'out')">
        <v-list-item-action>
          <v-icon color="error">mdi-minus</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Saída</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {};
</script>

<style></style>
