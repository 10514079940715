<template>
  <div :id="transactionStatsStyle">
    <v-card :outlined="!isMobile" elevation="0">
      <v-card-text>
        <div class="d-flex align-end justify-space-between mt-2">
          <h2>Saldo Atual</h2>
          <h1 class="mt-3">{{ $format.decimal(balance.value_sum) }}</h1>
        </div>
        <v-divider class="mt-6 mb-8" />

        <div class="mt-2">
          <div class="d-flex">
            <h2>Balanço</h2>
          </div>
          <div class="d-flex justify-space-between mt-2">
            <div>
              <span>(+) Entradas</span>
            </div>
            <div class="text-right">
              <h3>{{ $format.decimal(sum(incomePaid)) }}</h3>
            </div>
          </div>
          <div class="d-flex justify-space-between mt-2">
            <div>
              <span>(-) Saídas</span>
            </div>
            <div class="text-right">
              <h3>{{ $format.decimal(sum(outcomePaid)) }}</h3>
            </div>
          </div>
          <v-divider class="mt-1 mb-1" />
          <div class="d-flex justify-space-between mt-2">
            <div>
              <span>(=) Balanço do Mês</span>
            </div>
            <div class="text-right">
              <h3>{{ $format.decimal(sum(monthBalance)) }}</h3>
            </div>
          </div>
        </div>

        <v-divider class="mt-6 mb-8" />
        <div class="mt-4">
          <div class="d-flex mb-1">
            <h2>Entradas</h2>
            <small class="ml-2">(Este Mês)</small>
          </div>
          <v-progress-linear
            rounded
            :value="percent(totalIncome, incomePaid)"
            color="#0EBC00"
          />
          <div class="d-flex justify-space-between mt-2">
            <div>
              <h3>{{ $format.decimal(sum(incomePaid)) }}</h3>
              <span class="text--secondary">Recebidas</span>
            </div>
            <div class="text-right">
              <h3>{{ $format.decimal(sum(incomeUnpaid)) }}</h3>
              <span class="text--secondary">A Receber</span>
            </div>
          </div>
        </div>

        <div class="mt-8">
          <div class="d-flex mb-1">
            <h2>Saídas</h2>
            <small class="ml-2">(Este Mês)</small>
          </div>
          <v-progress-linear
            rounded
            :value="percent(totalOutcome, outcomePaid)"
            color="error"
          />
          <div class="d-flex justify-space-between mt-2">
            <div>
              <h3>{{ $format.decimal(sum(outcomePaid)) }}</h3>
              <span class="text--secondary">Pago</span>
            </div>
            <div class="text-right">
              <h3>{{ $format.decimal(sum(outcomeUnpaid)) }}</h3>
              <span class="text--secondary">A Pagar</span>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    transactionsSum: {
      default: [],
    },
    balance: {
      default: {},
    },
  },

  data: () => ({
    incomePaid: (row) => row.type == "in" && row.status == "paid",
    incomeUnpaid: (row) => row.type == "in" && row.status == "pending",
    totalIncome: (row) => row.type == "in" && row.status !== "canceled",
    outcomePaid: (row) => row.type == "out" && row.status == "paid",
    outcomeUnpaid: (row) => row.type == "out" && row.status == "pending",
    totalOutcome: (row) => row.type == "out" && row.status !== "canceled",
    monthBalance: (row) => row.status == "paid",

    received: (row) => row.type == "in",
  }),

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    transactionStatsStyle() {
      return this.isMobile
        ? "transaction-stats-mobile"
        : "transaction-stats-desktop";
    },
  },

  methods: {
    sum(func) {
      return this.transactionsSum.reduce((sum, row) => {
        if (func(row)) {
          return sum + parseFloat(row.value_sum);
        }

        return sum;
      }, 0);
    },

    percent(total, part) {
      let sumTotal = Math.abs(this.sum(total));
      let sumPart = Math.abs(this.sum(part));

      return sumTotal > 0 ? (sumPart * 100) / sumTotal : 0;
    },
  },
};
</script>



<style lang="sass" scoped>
// h3
//   color: #5F5C6B
//   font-size: 18px
//   font-family: Poppins
//   font-style: normal
//   font-weight: 700
//   line-height: 27px

// .body-1
//   color: #4E4B59
//   font-size: 16px
//   font-family: Poppins
//   font-style: normal
//   font-weight: 600
//   line-height: 24px

// .body-2
//   color: #4E4B59
//   font-size: 16px
//   font-family: Poppins
//   font-style: normal
//   font-weight: 500
//   line-height: 24px

// .body-3
//   color: #AAA8B8
//   font-size: 16px
//   font-family: Poppins
//   font-style: normal
//   font-weight: 500
//   line-height: 24px

#transaction-stats-desktop
  .v-card
    border-radius: 16px

  .totals
    // display: flex
    // justify-content: space-between
    // margin-top: 16px
    // margin-bottom: 16px

#transaction-stats-mobile
  padding-top: 24px
  padding-left: 4px
  padding-right: 4px

  .v-progress-linear
    height: 8px !important

  .v-card
    background: #FFFFFF
    border-radius: 16px
    box-shadow: none

  .v-card__subtitle, .v-card__text, .v-card__title
    padding: 0 !important

  // .totals
  //   display: flex
  //   flex-direction: column
  //   margin-top: 16px
  //   margin-bottom: 16px

  //   padding: 6px 0 6px 0
</style>